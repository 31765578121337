export const environment = {
  production: true,
  local: false,
  baseClientUrl: 'https://optimy.app',
  baseApiUrl: '',
  name: 'Production',
  appVersion: require('../../package.json').version,
  appToken: '75e69e66b5ea924254992347fa8c6bd3',
  backendUrl: 'https://production-optimy-core-v2.com/api/v1',
  logRocketProjectIdentification: 'optimy/agent-portal-prod',
  firebase: {
    apiKey: 'AIzaSyCFKb_MlXo3xmz1ktmgqscKdbrGlC5XNEI',
    authDomain: 'mpwrapp-49d3a.firebaseapp.com',
    databaseURL: 'https://mpwrapp-49d3a.firebaseio.com',
    projectId: 'mpwrapp-49d3a',
    storageBucket: 'mpwrapp-49d3a.appspot.com',
    messagingSenderId: '1051580673576',
    appId: '1:1051580673576:web:42d461a8d7aec2411ecf1d',
    measurementId: 'G-TGFF9YGZP2'
  },
  cellcomGtmId: 'GTM-TNJVQ3G',
  royfossGtmId: 'GTM-K5JWFZ5',
  googleGeoCodingKey: 'AIzaSyA7QU1iqE4z4fqSA_Uz8yqmC1PfctzpqbA',
  staffingTenantId: 11,
  getBuildNumberApi: 'https://lambda.optimy.app/agentstatus',
  environmentName: 'production',
  hyundaiTenantId: 123,
  delonghiTenantId: 34
};
